import React from 'react'
import BlogTemplate from 'templates/blog-template'
import { graphql } from 'gatsby'

const BlogTag = props => {
  return <BlogTemplate header={`Blog - ${props.pageContext.tag}`} {...props} />
}

export const pageQuery = graphql`
  query blogTag($tag: String!) {
    allSanityBlogPost(
      sort: { fields: publishedDate, order: DESC }
      filter: { tags: { elemMatch: { titlePlural: { eq: $tag } } }, publishedDate: { ne: null } }
    ) {
      nodes {
        authors
        category {
          slug
          titlePlural
        }
        id
        featuredImage {
          asset {
            gatsbyImageData(width: 750)
          }
        }
        slug
        title
      }
    }
  }
`

export default BlogTag
